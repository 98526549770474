import React, { createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

export const ServicesContext = createContext();

export const ServicesProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsLesService(sort: { order: ASC, fields: id }) {
        edges {
          node {
            id
            titre
            description
            photo {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <ServicesContext.Provider value={[data]}>
      {children}
    </ServicesContext.Provider>
  );
};
