import React from "react";
import styled from "styled-components";
import Back from "../images/Parallax.jpg";

const Parallax = () => {
  return <ParallaxWrapper></ParallaxWrapper>;
};

const ParallaxWrapper = styled.div`
  background: url(${Back});
  filter: brightness(0.7);
  min-height: 450px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 769px) {
    background: url(${Back});
    filter: brightness(0.7);
    min-height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export default Parallax;
