import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ServicesContext } from "../context/ServicesContext";
import ReactMarkdown from "react-markdown";
const Services = () => {
  const [data] = useContext(ServicesContext);
  const [services] = useState(data.allDatoCmsLesService.edges);

  return (
    <ServicesWrapper>
      <h2>Je vous propose</h2>
      <div className="Content">
        <p>
          Un panel d'exercices simples qui suit un protocole personnalisé
          suivant l'objectif que vous vous êtes fixé.
        </p>
        <p>
          Afin de vous permettre de vous recentrer sur vous-même et de pouvoir
          être à l’écoute de votre corps, de vos 5 sens, de vos émotions et
          parvenir à un bien-être dans votre quotidien.
        </p>
        <div className="ContentCards">
          {services.map(({ node: service }) => {
            return (
              <div className="cols" key={service.id}>
                <div
                  className="col"
                  ontouchstart="this.classList.toggle('hover');">
                  <div className="container">
                    <div
                      className="front"
                      style={{
                        background: `url(${service.photo.url})`,
                        backgroundSize: "cover",
                      }}>
                      <div className="inner">
                        <p className="title">{service.titre} </p>
                      </div>
                    </div>
                    <div className="back">
                      <div className="inner">
                        <ReactMarkdown>{service.description}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col"
                  ontouchstart="this.classList.toggle('hover');"></div>
              </div>
            );
          })}
        </div>
      </div>
    </ServicesWrapper>
  );
};

const ServicesWrapper = styled.div`
  min-height: 100vh;
  padding-top: 5rem;
  font-family: "Zen Old Mincho", serif;
  color: var(--green);

  h2 {
    text-align: center;
    font-size: 2rem;
  }
  .Content {
    padding: 0 0 3rem 0;
    text-align: justify;
    justify-content: space-evenly;
    justify-items: center;
    p {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
  }
  .ContentCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    p {
      font-size: 0.9rem;
    }

    .cols {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .col {
      margin: 1rem;
      cursor: pointer;
    }

    .container {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-perspective: 1000px;
      perspective: 1000px;
    }

    .front,
    .back {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      background-position: center;
      -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
        -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      text-align: center;
      min-height: 350px;
      width: 16rem;
      height: auto;
      color: var(--background-color);
    }

    .back {
      background: var(--background-color);
      color: var(--green);
      overflow: hidden;
    }

    .front:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      opacity: 0.6;
      background-color: var(--green);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 10px;
    }
    .container:hover .front,
    .container:hover .back {
      -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
        -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
    }

    .back {
      position: absolute;
      top: 0;
      left: 0;
      width: 16rem;
      overflow: hidden;
    }

    .inner {
      -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
      transform: translateY(-50%) translateZ(60px) scale(0.94);
      top: 50%;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 2rem 5px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: 1px solid transparent;
      -webkit-perspective: inherit;
      perspective: inherit;
      z-index: 2;

      p {
        filter: brightness(1) !important;
      }

      .title {
        line-height: 3rem;
      }
    }

    .container .back {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    .container .front {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    .container:hover .back {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    .container:hover .front {
      -webkit-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    .front .inner p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
      position: relative;
    }

    .front .inner p:after {
      content: "";
      width: 4rem;
      height: 2px;
      position: absolute;
      background: #c6d4df;
      display: block;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -0.75rem;
    }

    .front .inner span {
      color: rgba(255, 255, 255, 0.7);
      font-family: "Montserrat";
      font-weight: 300;
    }

    @media screen and (max-width: 64rem) {
      .col {
        width: calc(33.333333% - 2rem);
      }
    }

    @media screen and (max-width: 48rem) {
      .col {
        width: calc(50% - 2rem);
      }
    }

    @media screen and (max-width: 32rem) {
      .col {
        width: 100%;
        margin: 0 0 2rem 0;
      }
    }
  }
`;

export default Services;
