import React, { createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

export const CitationContext = createContext();

export const CitationProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsLaSophrologie {
        edges {
          node {
            id
            titre
            description
          }
        }
      }
    }
  `);

  return (
    <CitationContext.Provider value={[data]}>
      {children}
    </CitationContext.Provider>
  );
};
