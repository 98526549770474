import React, { useEffect, useState } from "react";
import Citation from "../components/Citation";
import Layout from "../components/Layout";
import Landing from "../components/Landing";
import Services from "../components/Services";
import Parallax from "../components/Parallax";
import { ServicesProvider } from "../context/ServicesContext";
import { CitationProvider } from "../context/CitationContext";
import Seo from "../components/seo";
const IndexPage = () => {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <Layout offset={offset}>
      <Seo title="Magali Sophrologue Toulon" />
      <Landing offset={offset} />
      <CitationProvider>
        <Citation offset={offset} />
      </CitationProvider>
      <Parallax />
      <ServicesProvider>
        <Services offset={offset} />
      </ServicesProvider>
    </Layout>
  );
};

export default IndexPage;
