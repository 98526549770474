import React from "react";
import styled, { keyframes } from "styled-components";
import Logo from "../images/Logo.svg";
import LandingPicture from "../images/PictureLanding.jpg";
import { fadeInLeft, fadeInRight } from "react-animations";
const fadeAnimation = keyframes`${fadeInLeft}`;
const fadeAnimation2 = keyframes`${fadeInRight}`;

const Landing = ({ offset }) => {
  return (
    <LandingWrapper>
      <div className="LogoWrapper">
        <img src={Logo} alt="Magali Sophrologue avec en l'arbre de vie" />
      </div>
      <div className="PictureWrapper">
        <img src={LandingPicture} alt="Une tasse de thé" />
      </div>
    </LandingWrapper>
  );
};

const LandingWrapper = styled.div`
  width: 100%;
  height: auto;
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  @media screen and (max-width: 874px) {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;

    .LogoWrapper {
      width: 100%;
      text-align: center;
    }
    .PictureWrapper {
      display: none;
    }
  }
  .LogoWrapper {
    img {
      width: 50rem;
      margin-top: -40px auto;
      animation: 4s ${fadeAnimation};
    }
  }

  .PictureWrapper {
    position: relative;
    margin-top: 3rem;
    width: 80%;
    height: 70%;
    border-radius: 50% 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    overflow: hidden;
    animation: 4s ${fadeAnimation2};
    img {
      position: absolute;
      filter: brightness(0.8);
      top: -40%;
      background-size: cover;
    }

    @media screen and (min-width: 1874px) {
      width: 90%;
      height: 80%;
      img {
        position: absolute;
        filter: brightness(0.8);
        top: -69%;
        background-size: cover;
      }
    }
  }
`;

export default Landing;
