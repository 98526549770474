import React, { useContext, useState } from "react";
import styled from "styled-components";
import { CitationContext } from "../context/CitationContext";
import ReactMarkdown from "react-markdown";

const Citation = () => {
  const [data] = useContext(CitationContext);
  const [citation] = useState(data.allDatoCmsLaSophrologie.edges);
  return (
    <CitationWrapper>
      <div className="Content">
        {citation.map(({ node: element }) => {
          return (
            <div key={element.id}>
              <h2>{element.titre}</h2>
              <ReactMarkdown className="Desc">
                {element.description}
              </ReactMarkdown>
            </div>
          );
        })}
      </div>
    </CitationWrapper>
  );
};

const CitationWrapper = styled.div`
  height: auto;
  font-family: "Zen Old Mincho", serif;
  color: var(--green);
  padding: 5rem 0;
  h2 {
    text-align: center;
    font-size: 2rem;
  }
  .Content {
    padding: 0 0 3rem 0;
    text-align: justify;
    .Desc {
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }
`;
export default Citation;
